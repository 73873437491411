.article-date {
  font-size: 13px;
  color: #666;
  padding-bottom: 10px;
}
.article-thumbnail {
  float: left;
  margin: 0 15px 15px 0;
  max-width: 200px;
  max-height: 200px;
}
.article-content {
  text-align: justify;
}
@media screen and (max-width: 576px) {
  .article-thumbnail {
    float: none;
    display: block;
    margin: 0 auto 15px auto;
  }

}
