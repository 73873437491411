@import 'colors';
.mim-container .leaflet-popup-content {
  min-width: 400px;
}
.mim-container .map-search-container-nodes {
  max-height: 420px;
}
.mim-legend {
  position: absolute;
  bottom: 16px;
  right: 0;
  z-index: 999999;
  background-color: rgba(255,255,255,0.7);
  padding: 5px 15px 5px 5px;
}
.mim-legend > ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.mim-legend > ul > li {
  font-size: 12px;
  line-height: 12px;
  color: #555;
}
.mim-legend-color {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right:4px;
}
.map-container {
  width: 100%;
  height: 500px;
  display: block;
  overflow: hidden;
  position: relative;
}
.map-container.fullscreen {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: auto;
}
.leaflet-container {
  display: block;
  width: 100%;
  height: 500px;
  position: relative;
}
.map-container.fullscreen > .leaflet-container {
  height: 100%;
}
.leaflet-default-icon-path {
  width: 30px !important;
  height: 30px !important;
  padding: 4px 8px;
  background-image: none!important;
}
.map-search-toggle {
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 0;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  right: 12px;
  top: 80px;
  z-index: 999;
  box-shadow: 0 1px 3px rgba(0,0,0,0.7);
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;
}
.map-search-toggle:hover {
  background-color: #eee;
}
.map-search-toggle:active {
  background-color: #d0d0d0;
}

.map-fullscreen-toggle {
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 0;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  right: 12px;
  top: 125px;
  z-index: 999;
  box-shadow: 0 1px 3px rgba(0,0,0,0.7);
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;
}
.map-fullscreen-toggle:hover {
  background-color: #eee;
}
.map-fullscreen-toggle:active {
  background-color: #d0d0d0;
}
.map-help-toggle {
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 0;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  right: 12px;
  top: 170px;
  z-index: 999;
  box-shadow: 0 1px 3px rgba(0,0,0,0.7);
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;
}
.map-help-toggle:hover {
  background-color: #eee;
}
.map-help-toggle:active {
  background-color: #d0d0d0;
}
.map-search-container {
  width: 250px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.7);
  position: absolute;
  top: 0;
  right: -255px;
  transition: all 0.5s;
  z-index: 9999999;
}
.map-search-container.visible {
  right: 0;
}
.close-map-search-container {
  width: 20px;
  height: 20px;
  cursor: pointer;
  line-height: 20px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
}
.close-map-search-container:hover {
  color: #666;
}
.close-map-search-container:active {
  color: #000;
}
.map-search-input {
  padding: 0 15px;
  margin: 20px 0 10px 0;
  position: relative;
}
.map-search-input input {
  padding-right: 20px;
}
.map-search-input > i {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}
.map-search-input > i:hover {
  color: #666;
}
.map-search-input > i:active {
  color: #000;
}
.map-search-container-nodes {
  height: 530px;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.map-search-container-nodes::-webkit-scrollbar {
  display: none;
}
.map-search-container-nodes ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.map-search-container-nodes > ul > li {
  cursor: pointer;
  padding: 5px 15px;
  border-bottom: 1px solid #ddd;
}
.map-search-container-nodes > ul > li:first-child {
  border-top: 1px solid #ddd;
}
.map-search-container-nodes > ul > li:hover {
  background-color: #f9f9f9;
}
.map-search-container-nodes > ul > li:active {
  background-color: #f0f0f0;
}
.marker-cluster {
  background: #ffffff;
  border-style: dashed;
  border-radius: 50% !important;
  color: #000000;
  line-height: 37px;
  text-align: center;
  opacity: 0.7 !important;
  transition: opacity 0.4s;
}
.marker-cluster-large {
  border-width: 12px;
  border-color: $blue;
  width: 56px !important;
  height: 56px !important;
}
.marker-cluster-medium {
  border-width: 8px;
  border-color: $light-blue;
  width: 48px !important;
  height: 48px !important;
}
.marker-cluster-small {
  border-width: 4px;
  border-color: $light-blue;
  width: 40px !important;
  height: 40px !important;
}
.marker-cluster:hover {
  opacity: 1 !important;
}
.marker-cluster div {
  border-radius: 50%;
  margin: 1px;
  font: 12px Helvetica Neue,Arial,Helvetica,sans-serif;
  text-align: center;
}

.marker-cluster div span{
  text-shadow: -1px -1px 5px #fff;
  font-size: 16px;
  line-height: 30px;
}
.marker-cluster div span:hover {
  color: #000;
}

.popup-description {
  padding-top: 15px;
}
.popup-description > div {
  margin-bottom: 5px;
}
.location-label {
  background-color: #fff;
  border: $blue;
  border-radius: 4px;
  min-width: 40px;
  height: 40px;
}
.map-select-source {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 999;
  box-shadow: 0 1px 3px rgba(0,0,0,0.4);
  border-radius: 4px;
}
.popup-people > h5, .popup-organisations > h5, .popup-events > h5 {
  display: flex;
  justify-content: flex-end;
}
.marker-cluster-medium div, .marker-cluster-small div {
  background-color: #fff;
}