$dark-grey: #24302a;
$darker-grey: darken( $dark-grey, 3% );
$light-grey: #EAECEF;
$dark-grey-hover: lighten( $dark-grey, 15% );

$red: #FA5554;
$crem: #F4F3F0;
$link: #3c6651;
$link-lighter: lighten( $link, 15% );
$link-darker: darken( $link, 15% );


$footer-grey: lighten( $dark-grey, 10% );

$dark-brown: #252E31;
$dark-brown-hover: lighten( $dark-brown, 20% );
$dark-brown-active: darken( $dark-brown, 20% );

$green: #057f4a;
$dark-green: darken( $green, 12% );
$darker-green: darken( $green, 24% );
$light-green: lighten( $green, 20% );

$blue: #0777af;
$light-blue: lighten( $blue, 20% );
$dark_blue: #00334d;
$white: #ffffff;
$black: #000000;

$teal: #82e2ed;
$light-teal: lighten( $teal, 15% );
$light-teal2: lighten( $teal, 30% );
$dark-teal1: darken( $teal, 15% );
$dark-teal2: darken( $teal, 30% );
$dark-teal3: darken( $teal, 45% );
$dark-teal4: darken( $teal, 60% );

$timeline-teal: #00cbff;
