@font-face {
font-family: 'Helvetica Neue Italic';
font-style: normal;
font-weight: normal;
src: local('Helvetica Neue Italic'), url('HelveticaNeueItalic.woff') format('woff');
font-display: swap;
}

@font-face {
font-family: 'Helvetica Neue Light';
font-style: normal;
font-weight: normal;
src: local('Helvetica Neue Light'), url('HelveticaNeueLight.woff') format('woff');
font-display: swap;
}

@font-face {
font-family: 'Helvetica Neue Light Italic';
font-style: normal;
font-weight: normal;
src: local('Helvetica Neue Light Italic'), url('HelveticaNeueLightItalic.woff') format('woff');
font-display: swap;
}

@font-face {
font-family: 'Helvetica Neue Medium';
font-style: normal;
font-weight: normal;
src: local('Helvetica Neue Medium'), url('HelveticaNeueMedium.woff') format('woff');
font-display: swap;
}

@font-face {
font-family: 'Helvetica Neue Bold';
font-style: normal;
font-weight: normal;
src: local('Helvetica Neue Bold'), url('HelveticaNeueBold.woff') format('woff');
font-display: swap;
}

@font-face {
font-family: 'Helvetica Neue Bold Italic';
font-style: normal;
font-weight: normal;
src: local('Helvetica Neue Bold Italic'), url('HelveticaNeueBoldItalic.woff') format('woff');
font-display: swap;
}
