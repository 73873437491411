.classpieces-list-thumbnail {
  display: block;
  position: relative;
}
.classpiece-thumbnail {
  width: 100%;
  height: 100%;
  position: absolute;
  background-position: 50%;
  background-size: cover;
  z-index: 9;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
