@import 'colors';

body {
  font-size: 15px;
  font-family: "Roboto",Helvetica,Arial,sans-serif;
}

h1, h2, h3, h4, h5 {
  font-family: "Spartan","Helvetica Neue",Helvetica,Arial,sans-serif;
}
.hidden {
  display: none !important;
}
.container-fluid {
  padding: 0;
}
.text-justify {
  text-align: justify;
}
.content-container a, .content-container a:link, .content-container a:visited {
  color: $link;
  font-weight: 600;
  text-decoration: none;
}
.content-container a:hover {
  color: $link-lighter;
  text-decoration: underline;
}
.content-container a:active {
  color: $link-darker;
}
@media (min-width: 768px) and (max-width: 991px) {
  .container, .container-md, .container-sm {
    max-width: 738px;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .container, .container-sm {
    max-width: 546px;
    padding-left: 0;
    padding-right: 0;
  }
}
.content-container.main-content-container {
  padding-bottom: 0;
}
.app-body {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $darker-grey;
}
.top-bar {
  background-color: $footer-grey;
  height: 30px;
}
.top-bar ul{
  margin: 0;
  padding: 0;
}
.top-bar .top-bar-list > li {
  display: inline-block;
  list-style-type: none;
}
.top-bar .social-icons > li:hover {
  background-color: $dark-grey-hover
}
.top-bar .social-icon {
  color: #fff;
  display: block;
  width: 28px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin: 0 1px;
}
.top-bar .top-bar-link {
  color: #fff;
  display: block;
  height: 30px;
  line-height: 30px;
}
.top-bar .top-bar-link span {
  font-size: 13px;
}

@media screen and (max-width: 400px) {
  .top-bar {
    height: 50px;
  }
  .top-bar .social-icon {
    width: 48px;
    height: 50px;
    line-height: 50px;
  }
  .top-bar .social-icon i {
    font-size: 22px;
  }
}
.header {
  background-color: #fff;
}

.main-nav {
  border-bottom: 1px solid $light-grey;
  transition: all 0.3s linear;
}
.main-nav .navbar {
  padding-top: 0;
  padding-bottom: 0;
}
.main-nav .navbar a {
  text-decoration: none;
}
.main-nav .nav-item {
  padding: 0;
}
.main-nav .nav-item a {
  color: #444;
}
.main-nav .nav-item {
  border-bottom: 2px solid #FFF;
  background-color: #fff;
}
.main-nav .nav-item:hover {
  border-bottom: 2px solid $dark-grey;
}
.main-nav .navbar-toggler-icon {
  line-height: 1.5em;
}
.main-nav .navbar-toggler-nopadding {
  padding: 0;
}
.main-nav .navbar-toggler:focus {
  box-shadow: 0 0 0 0.25rem rgba(0,0,0,0.1);
}
.main-nav .nav-item > a,  .main-nav .nav-item > .nav-item-wc > a{
  padding: 15px;
  display: block;
  font-size: 14px;
}

.navbar-nav > li.dropdown:hover > ul.dropdown-menu {
  display: block;
}

@media only screen and (max-width: 766px) {
  .navbar-nav > li.dropdown:hover > ul.dropdown-menu {
    display: none;
  }
  .nav-item-wc {
    display: flex;
  }
  .nav-item-wc > a {
    flex: 1;
  }
  .nav-item-wct {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
  }
  .nav-item-wct:active {
    background-color: #ddd;
  }
  .navbar-nav > li.dropdown.active > ul.dropdown-menu {
    display: block;
  }
}
@media only screen and (min-width: 767px) {
  .nav-item-wct {
    display: none;
  }
}

.navbar-nav > li.dropdown ul.dropdown-menu {
  border-radius: 0;
  border: 1px solid #eee;
  padding: 0;
}
.navbar-nav > li.dropdown > ul.dropdown-menu > li.dropdown:hover > ul.dropdown-menu {
  display: block;
  top: 0;
  left: 100%;
}
.navbar-nav > li.dropdown > ul a {
  padding: 10px;
}
.navbar-nav > li.dropdown.right > ul.dropdown-menu {
  right: 0;
  left: auto;
}
.navbar-nav > li.dropdown.right > ul.dropdown-menu ul {
  left: -158px !important;
  right: auto;
}
.navbar-nav > li.dropdown > ul.dropdown-menu > li.dropdown.right ul {
  left: -158px !important;
  right: auto;
}
.navbar-nav > li.dropdown ul.dropdown-menu > li > a {
  padding: 8px;
  white-space: nowrap;
}
.navbar-nav > li.dropdown ul.dropdown-menu > li, .navbar-nav > li.dropdown ul.dropdown-menu > li:hover {
  border: 0 none;
}
.navbar-nav > li.dropdown ul.dropdown-menu > li > a:hover {
  border: 0 none;
  background-color: #ddd;
  width: calc(100% - 1px);
}
.navbar-nav > li.dropdown.nav-item > ul.dropdown-menu > li.dropdown.nav-item > div.nav-item-wc > a {
  padding: 8px !important;
}
.navbar-nav > li.dropdown.nav-item > ul.dropdown-menu > li.dropdown.nav-item > div.nav-item-wc > a:hover {
  background-color: #ddd;
}
.navbar-nav > li.dropdown.nav-item > ul.dropdown-menu > li.dropdown.nav-item > div.nav-item-wc > a::after {
  content: "\f105";
  font-family: 'Font Awesome 5 Free';
  position: absolute;
  right: 6px;
}
@media only screen and (max-width: 767px) {
  .navbar-nav > li.dropdown.nav-item > ul.dropdown-menu > li.dropdown.nav-item > div.nav-item-wc > a::after {
    content: "";
  }
}

  /* fix for open state */
.navbar-nav .open > .dropdown-menu {
  display: none;
}
.search-trigger > span {
  padding: 14px 15px;
  cursor: pointer;
  display: block;
}
.search-trigger {
  border-bottom: 2px solid #fff;
}
.search-trigger:hover {
  border-bottom: 2px solid #24302a;
}
.generic-search-input {
  position: absolute;
  width: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  transition: 0.2s opacity;
  z-index: -1;
  height: 0;
}
.generic-search-input.visible {
  opacity: 0.8;
  height: 50px;
  z-index: 999999;
  height: 40px;
}
.generic-search-input form {
  display: none;
}
.generic-search-input.visible form {
  display: block;
}
.generic-search-input input {
  width: 100%;
  background-color: $darker-green;
  color: #fff;
  border: 1px solid #ddd;
  line-height: 40px;
  padding: 0 40px 0 15px;
}
.generic-search-input .search-submit {
  position: absolute;
  right: 5px;
  top: 2px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  color: #f0f0f0;
  line-height: 36px;
  text-align: center;
}
.search-results {
  max-height: 300px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-bottom: 20px;
}
.search-results::-webkit-scrollbar {
  display: none;
}
.content-container {
  background-color: $crem;
  padding: 30px 0 40px 0 !important;
}

.people-list {
  margin-bottom: 10px;
}
.people-list-thumbnail {
  max-width: 40px;
  margin-right: 10px;
}

.bw-logo {
  display: block;
  white-space: nowrap;
}
.logo-container {
  display: block;
}
@media only screen and (max-width: 766px) {
  .logo-container {
    padding: 10px 0;
  }
}
.logo-img {
  height: 28px;
}
.bw-logo .simple-text {
  color: #333;
  text-transform: uppercase;
  display: inline-block;
  font-size: 19px;
  line-height: 19px;
  vertical-align: middle;
}
.bw-logo .simple-text.icon {
  position: relative;
  width: 28px;
  height: 22px;
}
.bw-logo .bw-container {
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  top: -5px;
}
.bw-logo
.triangle-left {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border-bottom: 20px solid #333;
  border-right: 20px solid transparent;
  z-index: 1;
}
.bw-logo
.triangle-left-inner {
  position: absolute;
  bottom: 2px;
  left: 2px;
  width: 0;
  height: 0;
  border-bottom: 13px solid #fff;
  border-right: 13px solid transparent;
  z-index: 2;
}
.bw-logo
.triangle-right {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border-bottom: 20px solid #333;
  border-left: 20px solid transparent;
  z-index: 3;
}
.bw-logo
.triangle-right-inner {
  position: absolute;
  bottom: 2px;
  left: 5px;
  width: 0;
  height: 0;
  border-bottom: 13px solid #fff;
  border-left: 13px solid transparent;
  z-index: 4;
}
.filter-body {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.filter-body::-webkit-scrollbar {
  display: none;
}
.filter-body .form-group {
  position: relative;
  margin-bottom: 0;
}
.filter-body .form-check-input {
  margin-left: 0;
  margin-top: 0;
}

.show-classpiece {
  cursor: pointer;
  padding-bottom: 15px;
}

.zoom-container {
  margin: 0 0 0 5px;
  position: absolute;
  right: 16px;
  top: 80px;
  z-index: 3;
}
.zoom-action {
  cursor: pointer;
  background-color: #FFF;
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  transition: all 0.3s;
}
.zoom-container .zoom-action:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.zoom-container .zoom-action:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.zoom-action:hover {
  background-color: #eee;
}
.zoom-action:active {
  background-color: #d0d0d0;
}
.zoom-action.active {
  background-color: #466ddb;
  color: #fff;
}
/*
.zoom-action.active.color1 {
  background-color: #DE9A96;
}
.zoom-action.active.color2 {
  background-color: #F5D98B;
  color: #333;
}
.zoom-action.active.color3 {
  background-color: #FAF3A5;
  color: #333;
}
.zoom-action.active.color4 {
  background-color: #82CEB6;
}
.zoom-action.active.color5 {
  background-color: #96E3E6;
}
.zoom-action.active.color6 {
  background-color: #89BDE0;
}
*/

.pan-container {
  width: 60px;
  height: 60px;
  margin-top: 20px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  right: 5px;
  top: 160px;
  z-index: 3;
}
.pan-action {
  position: absolute;
  display: block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 50%;
  font-size: 14px;
}
.pan-action:hover {
  background-color: #eee;
}
.pan-action:active {
  background-color: #d0d0d0;
}

.pan-action.up {
  top: 0;
  left: 18px;
}
.pan-action.right {
  top: 18px;
  right: 0;
}
.pan-action.down {
  bottom: 0;
  left: 18px;
}
.pan-action.left {
  top: 18px;
  left: 0;
}
.classpiece-people {
  position: absolute;
  z-index: 1;
  cursor: grab;
  width: 100%;
  height: 100%;
}
.classpiece-people:active {
  cursor: grabbing;
}

.metadata-title {
  font-weight: 500;
  text-transform: capitalize;
}
.list-items {
  list-style-type: none;
  margin: 0 0 0 15px;
  padding: 0;
}
.card label {
    font-size: 0.8571em;
    margin-bottom: 5px;
    color: #9A9A9A;
}
.classpiece-tooltip {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 9;
  background-color: #000;
  color: #fff;
  border-radius: 4px;
  padding: 5px 15px;
  font-size: 13px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 8px 0px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px -2px;
  max-width: 200px;
}
.classpiece-tooltip.active {
  visibility: visible;
  opacity: 0.9;
}
.classpiece-triangle-left {
  position: absolute;
  top: 5px;
  left: -12px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 15px 10px 0;
  border-color: transparent #000000 transparent transparent;
}
.classpiece-triangle-right {
  position: absolute;
  top: 5px;
  right: -12px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 15px;
  border-color: transparent transparent transparent #000000;
}
.tool-box {
  float: right;
  font-size: 15px;
  line-height: 2rem;
}
.tool-box .action-trigger {
  cursor: pointer;
}
.tool-box > div {
  display: inline-block;
  margin-right: 10px;
}
.tool-box > div:last-child {
  margin-right: 0;
}

.alternate-appelations > .label {
  padding-bottom: 10px;
  font-size: 15px;
  font-weight: bold;
  color: #555;
  cursor: default;
}
section {
  padding: 80px 0;
  position: relative;
  display: block;
}
.section-title {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 24px;
  margin-bottom: 40px;
  color: $dark-green;
  font-weight: 300;
  font-family: 'Spartan',Helvetica,Arial,sans-serif;
  position: relative;
}
.section-title > span {
  border-bottom: 3px solid $dark-green;
  padding: 0 15px 0 0;
  display: inline-block;
}
.section-title > span > span {
  font-size: 32px;
}

.section-numbers {
  padding: 30px 0;
}
.section-numbers-bg {
  background-color: $dark-green;
  background-attachment: fixed;
  background-size: cover;
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.section-numbers-bg-gradient {
  background-image: linear-gradient(to right, rgba($green,0.9),rgba($green,0.6), rgba($green,0.8));
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.section-numbers-content {
  z-index: 2;
  position: relative;
}
.section-numbers-content i {
  display: block;
  font-size: 40px;
}
.numbers-row .home-count {
  color: #fff;
  text-align: center;
  padding-bottom: 15px;
}
.numbers-row .home-count-label {
  font-size: 48px;
  font-weight: 400;
}
.numbers-row span {
  padding: 0;
  margin-top: -18px;
  display: block;
  text-transform: lowercase;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}
.white-section {
  background-color: #fff;
}

.headerNavbar-container{
  position: fixed;
  top: 0;
  opacity: 0.8;
  font-size: 12px;
  z-index:999999999999999999;
}

.clear-filters {
  font-size: 13px;
  cursor: pointer;
}
.clear-filters:hover {
  color: #666;
}
.clear-filters:active {
  color: #222;
}

.footer {
  min-height: 200px;
  background-color: $dark-grey;
  padding: 50px;
  color: #fff;
}
.footer-menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.footer-menu > li {
  padding: 0 0 10px 0;
}
.contact-info {
  color: #fff;
}
.contact-info h4 {
  font-size: 17px;
}
.mu-footer {
  background-color: #fff;
  padding: 40px 0;
}
.mu-footer .small-text {
  font-size: 8pt;
  line-height: 11pt;
}
.mu-footer-logo {
  border-radius: 4px;
  max-height: 60px;
  background-color: #fff;
  padding: 10px;
  margin: 5px;
}
.muahi-footer-logo {
  max-height: 60px;
  margin: 8px;
}
.spcm-footer-logo {
  max-height: 60px;
  margin: 8px;
}
.footer-logo {
  border-radius: 4px;
  max-height: 60px;
  background-color: #fff;
  padding: 5px;
  margin: 5px;
}
.footer-2 {
  background-color: $darker-grey;
  padding: 20px 0;
  border-top: 1px solid #444;
  color: #fff;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.toggle-info-btn i {
  transition: 0.3s all;
}
.toggle-info-btn i.closed {
  transform: rotate(90deg);
}
.metadata-info-container {
  transition: 0.3s all cubic-bezier(0, 1, 0.5, 1);
  max-height: 100%;
  border-bottom: 1px solid #eee;
  margin-top: 15px;
}
.metadata-info-container.closed {
  max-height: 36px;
  overflow: hidden;
}
.people-info-container {
  transition: 0.3s all linear-gradient;
  max-height: 100%;
}
.people-info-container.closed {
  max-height: 0;
  overflow: hidden;
}
.people-info-container.closedWithSearch {
  max-height: 78px;
  overflow: hidden;
}
.classpiece-details-container {
  margin-top: 15px;
}
.classpiece-details-container > div {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.classpiece-details-container > div:last-child {
  border-bottom: 0 none;
}
.classpiece-details-description {
  margin-bottom: 20px;
}
.classpiece-details-table {
  margin-bottom: 20px;
  width: 100%;
  empty-cells: show;
}
.classpiece-details-table th {
  vertical-align: top;
  padding: 2px;
  border-bottom: 1px solid #ededed;
  white-space: nowrap;

  font-weight: 400;
  width: 15%;
}
.classpiece-details-table td {
  vertical-align: top;
  padding: 2px;
  border-bottom: 1px solid #ededed;

  width: 85%;
}
.classpiece-details-table tr:last-child th, .classpiece-details-table tr:last-child td {
  border-bottom: none;
}
.tag-list {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0 0 10px 0;
  padding: 6px 0 0 0;
}
.tag-list-people {
  justify-content: flex-start;
  max-height: 500px;
  overflow-y: auto;
  padding: 6px 0 0 0;
}
.tag-list li {
  margin: 0 5px 0 0;
}
@media screen and (max-width: 400px) {
  .tag-list li  {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.tag-list li a:link, .tag-list li a:visited{
  color: #777;
}
.tag-list li a:hover, .tag-list li a:active{
  background: #999999;
  color: #ffffff;
  text-decoration: none;
}
.tag-list-pagination {
  white-space: nowrap;
}
.tag-list-pagination span {
  font-size: 12px;
  padding: 10px 0 0 5px;
  display: inline-block;
}
.tags-search-container {
  height: 0;
  display: block;
  transition: 0.2s height;
  overflow: hidden;
  margin-bottom: 5px;
}
.tags-search-container.visible {
  height: 32px;
}
.tag-bg {
  cursor: pointer;
  background: #e6e6e6;
  border-radius: 4px;
  flex: none;
  text-align: center;
  padding: 2px 5px;
}
.tag-item {
  box-sizing: content-box;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  display: inline-block;
  text-align: left;
  margin-bottom: 2px;
}
.tag-item > .event-label {
  color: #444;
}
.tag-item > span {
  display: block;
}
.tag-item b {
  font-size: 13px;
}
.tag-dot {
  margin: 0 5px 10px 0;
}
.pull-icon-middle {
  margin-top: 3px;
}
.classpiece-search {
  margin-right: 3px;
}
.classpiece-search .action-trigger {
  display: block;
  border: 1px solid #ced4da;
  border-radius: .2rem;
  width: 22px;
  height: 22px;
  margin-top: 3px;
  line-height: 22px;
  text-align: center;
  font-size: 12px;
}
.person-thumbnail-container {
  height: 400px;
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  justify-content: center;
}
.person-thumbnailImage {
  cursor: pointer;
  max-width: 100%;
  max-height: 100%;
}
.person-label {
  text-align: center;
  margin-top: 10px;
}
.person-details-container > div:last-child, .item-details-container > div:last-child {
  border-bottom: 0 none;
}
.person-info-container, .item-info-container {
  border-bottom: 1px solid #eee;
}
.person-info-table, .item-info-table {
  margin-top: 15px;
  margin-bottom: 20px;
  width: 100%;
  empty-cells: show;
}
.person-des-container {
  margin-top: 3%;
  margin-left: 10%;
  width: 80%;

  line-height: 1.8;
}
.person-des-content {
  margin-left: 5%;
  font-size: 16px;
}
.events-list {
  margin-bottom: 10px;
}
.events-list-thumbnail {
  max-width: 40px;
  margin-right: 10px;
}
.organisations-list {
  margin-bottom: 10px;
}
.organisations-list-thumbnail {
  max-width: 40px;
  margin-right: 10px;
}
.filter-block {
  margin-bottom: 15px;
}
.filter-block h4 {
  position: relative;
}
.clear-filters {
  cursor: pointer;
  font-size: 13px;
  position: absolute;
  right: 5px;
  top: 5px;
}
.graph-container {
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  height: 600px;
  position: relative;
  overflow: hidden;
}
.graph-details-card {
  position: absolute;
  top: 10px;
  left: 10px;
  max-height: 370px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  width: 320px;
  max-width: 100%;
  z-index:99;
}
.graph-details-card-close {
  font-size: 16px;
  position: absolute;
  top: 2px;
  right: 0;
  width: 24px;
  width: 24px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  color: #666;
}
.graph-details-card-close:hover {
  color: #999;
}
.graph-details-card-close:active {
  color: #444;
}
.graph-details-collapse {
  font-size: 20px;
  position: absolute;
  top: 2px;
  right: 26px;
  width: 24px;
  width: 24px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  color: #666;
}
.graph-details-collapse > i {
  transition: transform 0.3s;
}
.graph-details-collapse > i.closed {
  transform: rotate(180deg);
}
.graph-details-collapse:hover {
  color: #999;
}
.graph-details-collapse:active {
  color: #444;
}
.graph-details-card .card-title {
  padding: 10px 15px 0 15px;
  margin: 0;
}
.graph-details-card .card-title > h4 {
  font-size: 18px;
}
.graph-details-card .node-relations-title .badge {
  cursor: pointer;
}
.graph-details-card .card-body {
  padding-top: 0;
}
.graph-details-card .card-content {
  overflow: auto;
  max-height: 222px;
  padding-right: 10px;
  margin-right: -10px;
}
.node-relations-list {
  overflow-y: auto;
  height: 120px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.node-relations-list::-webkit-scrollbar {
  display: none;
}
.graph-details-card .card-content .entries-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.graph-details-card .card-content .entries-list > li {
  padding: 2px 0;
  border-bottom: 1px solid #ddd;
}
.graph-details-card .card-content .entries-list > li:last-child {
  border-bottom: 0 none;
}
.graph-details-card .card-content .entries-list > li:hover {
  background-color: #eee;
}
.graph-details-card .card-content .entries-list > li .details {
  display: block;
}
.graph-details-card .card-content .entries-list > li .details > .label {
  display: flex;
}
.graph-details-card .card-content .entries-list > li .details > .label > div {
  flex-grow: 1;
}
.graph-details-card .card-content .entries-list > li .details > .actions {
  display: block;
}
.graph-details-card .card-content .entries-list li .graph-link-rel {
  font-size: 11px;
  color: #555;
}
.graph-details-card .card-content .entries-list li .arrow {
  font-size: 11px;
  color: #555;
  padding: 0 5px;
}
.graph-details-card .card-content .entries-list > li .label > .details img {
  max-height: 55px;
  margin: 0 0 4px 4px;
}
.graph-details-card .card-content .entries-list > li .btn {
  background-color: #fff;
  color: #6c757d;
  text-decoration: none;
}
.graph-details-card .card-content .entries-list > li .active {
  background-color: #ddd;
}
.graph-details-card .card-footer {
  padding: 10px 0 0 0;
  margin-top: -5px;
  margin-bottom: -5px;
  text-align: center;
  background-color: transparent;
  border: 0 none;
}
.graph-actions {
  position: absolute;
  top: 15px;
  right: 10px;
  width: 80px;
  text-align: center;
}
.graph-actions .zoom-panel {
  margin: 0 auto 15px auto;
  position: relative;
  z-index: 3;
  box-shadow: 0 1px 3px rgba(0,0,0,0.5);
  border-radius: 6px;
  width: 40px;
}
.graph-statistics-button {  
  width: 40px;
  height: 40px;
  margin: 10px auto 15px auto;
  border-radius: 50%;
  background-color: #fff;
  position: relative;
  right: 0;
  z-index: 3;
  box-shadow: 0 1px 3px rgba(0,0,0,0.7);
  text-align: center;
  line-height: 40px;
  transition: all 0.3s;
  cursor: pointer;
}
.graph-statistics-button:hover {
  background-color: #eee;
}
.graph-statistics-button:active {
  background-color: #d0d0d0;
}
.zoom-action {
  cursor: pointer;
  background-color: #FFF;
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  transition: all 0.3s;
}
.zoom-panel .zoom-action:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.zoom-panel .zoom-action:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.zoom-action:hover {
  background-color: #eee;
}
.zoom-action:active {
  background-color: #d0d0d0;
}

.graph-actions .pan-container, .options-trigger {
  padding: 0;
  margin: 0 auto 15px auto;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #fff;
  position: relative;
  z-index: 3;
  box-shadow: 0 1px 3px rgba(0,0,0,0.7);
  right: 0;
  top: 0;
}
.graph-search-toggle {
  width: 40px;
  height: 40px;
  margin: 10px auto 15px auto;
  border-radius: 50%;
  background-color: #fff;
  position: relative;
  right: 0;
  z-index: 3;
  box-shadow: 0 1px 3px rgba(0,0,0,0.7);
  text-align: center;
  line-height: 40px;
  transition: all 0.3s;
  cursor: pointer;
}
.graph-search-toggle:hover {
  background-color: #eee;
}
.graph-search-toggle:active {
  background-color: #d0d0d0;
}
.graph-help-toggle {
  width: 40px;
  height: 40px;
  margin: 10px auto 15px auto;
  border-radius: 50%;
  background-color: #fff;
  position: relative;
  right: 0;
  z-index: 3;
  box-shadow: 0 1px 3px rgba(0,0,0,0.7);
  text-align: center;
  line-height: 40px;
  transition: all 0.3s;
  cursor: pointer;
}
.graph-help-toggle:hover {
  background-color: #eee;
}
.graph-help-toggle:active {
  background-color: #d0d0d0;
}
.graph-search-container {
  width: 250px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.7);
  position: absolute;
  top: 0;
  right: -255px;
  transition: all 0.5s;
  z-index: 5;
}
.graph-search-container.visible {
  right: 0;
}
.close-graph-search-container {
  width: 20px;
  height: 20px;
  cursor: pointer;
  line-height: 20px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
}
.close-graph-search-container:hover {
  color: #666;
}
.close-graph-search-container:active {
  color: #000;
}
.graph-search-input {
  padding: 0 15px;
  margin: 20px 0 10px 0;
  position: relative;
}
.graph-search-input-type {
  margin: 10px 15px;
}
.graph-search-input input {
  padding-right: 20px;
}
.graph-search-input > i {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}
.graph-search-input > i:hover {
  color: #666;
}
.graph-search-input > i:active {
  color: #000;
}
.graph-search-container-nodes {
  height: 530px;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.graph-search-container-nodes::-webkit-scrollbar {
  display: none;
}
.graph-search-container-nodes > ul > li > div {
  cursor: pointer;
  padding: 5px 15px;
  border-bottom: 1px solid #ddd;
}
.graph-search-container-nodes > ul > li:first-child > div {
  border-top: 1px solid #ddd;
}
.graph-search-container-nodes > ul > li:hover > div {
  background-color: #f9f9f9;
}
.graph-search-container-nodes > ul > li:active > div {
  background-color: #f0f0f0;
}
.pan-action {
  position: absolute;
  display: block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 50%;
  font-size: 14px;
}
.pan-action:hover {
  background-color: #eee;
}
.pan-action:active {
  background-color: #d0d0d0;
}
.graph-loading {
  height: 600px;
  line-height: 500px;
  display: block;
  text-align: center;
}
.generic-loading {
  height: 200px;
  line-height: 150px;
  display: block;
  text-align: center;
}
.person-component-link {
  width: 60px;
  height: 60px;
  line-height: 50px;
  border: 4px solid $link;
  border-radius: 100%;
  position: relative;
  display: block;
  margin: 15px auto 0 auto;
  text-align: center;
  transition: 0.4s background-color;
}
.person-component-link > i {
  font-size: 38px;
  vertical-align: middle;
  transition: 0.4s color;
}
.person-component-link:hover {
  background-color: $link-lighter;
}
.person-component-link:hover > i {
  color: #fff;
}
.person-component-link-label {
  font-weight: 400;
  width: 100%;
  text-align: center;
  display: block;
  font-size: 13px;
}
.person-component-link-label label {
  font-weight: 400;
}
.related-nodes-paths-label {
  margin-bottom: 5px;
}
.related-nodes-paths-label > div {
  display: inline-block;
}
.related-nodes-paths-label .source {
  padding-right: 10px;
}
.related-nodes-paths-label .source span {
  color: $green;
}
.related-nodes-paths-label .target span {
  color: $blue;
}
.related-nodes-paths-block {
  position: relative;
  display: block;
}
.related-nodes-paths-count {
  position: absolute;
  top: 0;
  left: 0;
}
.related-nodes-paths {
  margin: 0;
  padding: 0 0 10px 25px;
  list-style-type: none;
  font-size: 13px;
}
.related-nodes-paths > li::before {
  content: "\f068";
  font-family: "FontAwesome";
  padding-right: 5px;
  font-size: 8px;
  vertical-align: middle;
}
.related-nodes-paths > li > div {
  display: inline-block;
}
.related-nodes-paths div {
  padding-right: 5px;
}
.related-nodes-paths .relationship {
  font-size: 11px;
  padding-right: 15px;
  position: relative;
  padding-bottom: 5px;
  top: -5px;
}
.related-nodes-paths .relationship .line {
  position: absolute;
  bottom: 4px;
  border-bottom: 2px solid #333;
  width: calc(100% - 12px);
  height: 2px;
}
.related-nodes-paths .relationship .arrow::after {
  content: "\f054";
  font-family: "FontAwesome";
  position: absolute;
  font-size: 13px;
  width: 15px;
  height: 15px;
  right: -2px;
  top: 7px;
  display: block;
  color: #333;
}

.img-viewer-search-toggle {
  width: 40px;
  height: 40px;
  margin: 10px auto 15px auto;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top:250px;
  right: 13px;
  z-index: 3;
  box-shadow: 0 1px 3px rgba(0,0,0,0.7);
  text-align: center;
  line-height: 40px;
  transition: all 0.3s;
  cursor: pointer;
}
.img-viewer-search-toggle:hover {
  background-color: #eee;
}
.img-viewer-search-toggle:active {
  background-color: #d0d0d0;
}
.img-viewer-search-container .graph-search-container-nodes {
  height: calc(100vh - 80px);
}

/* datepicker */
.ui-datepicker {
  width: 100%;
  height: calc(1.5em + .5rem + 2px);
}
.datepicker-wrapper {
  width: 100%;
  display: flex;
  margin: 0 0 15px 0;
  height: calc(1.5em + .5rem + 2px);
}
.datepicker-wrapper .btn-append {
  z-index: 0;
}
.filter-search-font {
  font-size: 7px;
}
.visualisations-image {
  width: 100%;
  margin-bottom: 10px;
}
.graph-drawing {
  position: absolute;
  left: 50%;
  top: 4px;
  width: 160px;
  margin-left: -80px;
  padding: 2px 5px;
  font-size: 14px;
  background-color: rgba(255,255,255,0.8);
  z-index: 9999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-style: italic;
}
@keyframes blink {50% { color: transparent }}
.graph-drawing .dot { animation: 1s blink infinite }
.graph-drawing .dot:nth-child(2) { animation-delay: 250ms }
.graph-drawing .dot:nth-child(3) { animation-delay: 500ms }

.home-visualisation-thumb {
  margin-bottom: 15px;
}
@media screen and (max-width: 767px) {
  .home-visualisation-block {
    margin-bottom: 15px;
  }
}
.graph-legend-panel {
  position: absolute;
  bottom: 10px;
  right: 5px;
  background-color: rgba(255,255,255,0.7);
  padding: 5px;
  z-index: 2px;
  font-size: 10px;
  border: 1px solid #ddd;
  box-shadow: 0 0 2px rgba(0,0,0,0.2);
  border-radius: 4px;
}
.graph-legend-panel > ul {
  list-style-type: none;
  margin: 0;
  padding: 5px;
}
.graph-legend-panel > ul > li {
  line-height: 15px;
  vertical-align: top;
}
.graph-legend-panel > ul > li > span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 1px solid;
  border-radius: 50%;
  vertical-align: middle;
}
.react-datepicker-wrapper {
  display: block !important;
}
.react-datepicker__input-container input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  line-height: 32px;
  color: #555;
}
.item-title-container {
  padding-bottom: 2%;
  border-bottom: 1px solid #eee;
}
.item-label {
  margin: 0;
}
.item-content-des {
  margin-bottom: 5%;
}
.item-content-des-title {
  font-size: 20px;
  margin-bottom: 17px;
}
.item-content-table {
  margin-bottom: 20px;
  width: 100%;
  empty-cells: show;
}
.item-content-table th {
  vertical-align: top;
  padding: 2px;
  border-top: 1px solid #ededed;
  font-weight: 400;
  width: 15%;
}
.item-content-table td {
  vertical-align: top;
  padding: 2px;
  border-top: 1px solid #ededed;
  width: 85%;
}
.item-content-table tr:last-child th, .item-content-table tr:last-child td {
  border-bottom: none;
}

.filter-spatial-marker {
  margin-left: 10px;
  font-size: 1rem;
}
.spatial-map-container {
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
  position: relative;
}
.spatial-map-container.spatials-list-map {
  max-height: 0;
  transition: 0.4s max-height;
}
.spatial-map-container.spatials-list-map.active {
  max-height: 500px;
}
.spatial-map-container.small h5 {
  font-size: 15px;
  padding: 10px 0 0 0;
}
.spatial-map-container.small .leaflet-container {
  max-height: 200px;
  border-radius: 4px;
}
.spatial-note-content {
  font-size: 16px;
}
.spatial-content-table {
  margin-bottom: 20px;
  width: 100%;
  empty-cells: show;
}
.spatial-content-table th {
  vertical-align: top;
  padding: 5px;
  border-top: 1px solid #ededed;
  font-weight: 400;
  width: 30%;
}
.spatial-content-table td {
  vertical-align: top;
  padding: 5px;
  border-top: 1px solid #ededed;
  width: 70%;
}
.spatial-content-table tr:last-child th, .spatial-content-table tr:last-child td {
  border-bottom: none;
}
.selectSpatial {
  width: 90%;
}

.event-details h4{
  font-size: 18px;
  padding: 10px 15px 0 30px;
}
.event-details-back {
  position: absolute;
  left: 10px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
}
.event-details-back:hover {
  color: #999;
}
.event-details-back:hover {
  color: #666;
}
.event-details-body {
  padding: 10px 15px;
  max-height: 300px;
  overflow-y: auto;
}
.toggle-event-details-related {
  cursor: pointer;
  position: relative;
  display: block;
}
.toggle-event-details-related i {
  position: absolute;
  right: 5px;
  width: 15px;
  height: 15px;
  line-height: 15px;
  vertical-align: bottom;
  transition: 0.2s transform;
  text-align: center;
}
.toggle-event-details-related i:hover {
  color: #999;
}
.toggle-event-details-related i:active {
  color: #666;
}
.toggle-event-details-related.active i {
  transform: rotate(180deg);
}
.related-event {
  background-color: rgb(93, 201, 16);
  margin-right: 10px;
}
.related-organisation {
  background-color: rgb(93, 201, 16);
  margin-right: 10px;
}
.related-person {
  background-color: rgb(93, 201, 16);
  margin-right: 10px;
}
.related-resource {
  background-color: rgb(93, 201, 16);
  margin-right: 10px;
}
.graph-search-toggle.events-timeline {
  position: absolute;
  top: 110px;
  right: 15px;
}
.graph-help-toggle.events-timeline {
  position: absolute;
  top: 170px;
  right: 15px;
}
.events-timeline-search-container {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: #fff;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0 3px 14px rgba(0,0,0,0.4);
  width: 250px;
  z-index:999;
}
.zoom-panel.events-timeline {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 9;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  width: 40px;
}
.events-list-container {
  max-height: 300px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.events-list-container::-webkit-scrollbar {
  display: none;
}

.filter-options-container {
  margin-top: 10px;
}
.filter-unseleted-container {
  margin-top: 10px;
}

.resources-list {
  margin-bottom: 10px;
}
.resources-list-thumbnail {
  max-width: 40px;
  margin-right: 10px;
}
.resource-details-container {
  border-bottom: 1px solid #eee;
  margin-top: 15px;
}
.resource-details-description {
  margin-bottom: 20px;
}
.resource-details-table {
  margin-bottom: 20px;
  width: 100%;
  empty-cells: show;
}
.resource-details-table th {
  vertical-align: top;
  padding: 2px;
  border-bottom: 1px solid #ededed;

  font-weight: 400;
  width: 15%;
}
.resource-details-table td {
  vertical-align: top;
  padding: 2px;
  border-bottom: 1px solid #ededed;

  width: 85%;
}
.resource-details-table tr:last-child th, .resource-details-table tr:last-child td {
  border-bottom: none;
}
.show-resource {
  cursor: pointer;
}
.badge-organisation {
  background-color: #9A8CF1;
  color: #fff;
}
.badge-resource {
  background-color: #07CBFF;
  color: #fff;
}
.badge-classpiece {
  background-color: #1FD8BF;
  color: #444;
}
.badge-resource img, .badge-classpiece img {
  display: block;
  max-width: 50px;
  margin: 0 auto 5px auto;
}
.error-container {
  border: 2px solid #f97054;
  color: #f97054;
  padding: 5px 15px;
  border-radius: 6px;
  margin-bottom: 10px;
}
.contact-form-success {
  padding: 60px 0;
  font-size: 18px;
}
.bottom-menu a {
  color: #CCC;
  display: block;
  padding: 6px 0;
}
.scroll-up {
  border: 2px solid $green;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.6;
  color: $green;
  width: 40px;
  height: 40px;
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
  text-align: center;
  font-size: 15px;
  line-height: 32px;
  transition: linear 0.3s;
  position: fixed;
  bottom: 50px;
  right: 30px;
  cursor: pointer;
  transition: opacity 0.2s linear 0s, visibility;
  z-index: 999;
}
.scroll-up:hover {
  opacity: 1;
}
.contact-img-logo {
  float: left;
  margin: 0 8px 0 0;
  width: 50px;
  height: 50px;
}
.cookies-consent-container {
  position: fixed;
  right:0;
  bottom: 0;
  left:0;
  border-top: 4px solid $darker-grey;
  padding: 15px 15px 10px 15px;
  z-index: 9999999999999999991;
  text-align: center;
  background-color: rgba(5,127,74,0.95);
  color: #fff;
}
.cookies-consent-content {
  position: relative;
  z-index: 2;
}
.cookies-consent-content a, .cookies-consent-content a:visited {
  color: #f6f9cf;
  text-decoration: underline;
}
.cookies-consent-content button {
  border: 2px solid #fff;
}
.home-about-logo {
  float: left;
  margin: 0 10px 10px 0;
}

.resource-list-icon-thumb {
  font-size: 20px;
  margin: 0 5px 5px 0;
}
.pdf-thumbnail {
  text-align: center;
  display: block;
  min-width: 100px;
  max-width: 200px;
  cursor: pointer;
}
.pdf-thumbnail i {
  font-size: 50px;
}
.pdf-thumbnail > label {
  cursor: pointer;
}
.item-thumbnails-nav {
  text-align: center;
  padding: 5px 0;
}
.item-thumbnails-nav > div {
  background-color: rgba(0,0,0,0.1);
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  line-height: 28px;
  text-align: center;
  color: #fff;
  border: 1px solid #d9d9d9;
  margin: 2px;
  cursor: pointer;
  transition: background-color 0.4s;
}
.item-thumbnails-nav > div:hover {
  background-color: rgba(0,0,0,0.2);
}
.item-thumbnails-nav > div:active {
  background-color: rgba(0,0,0,0.3);
}
.img-viewer-actions {
  position: absolute;
  top: 0;
  width: 80px;
  height: 100%;
  transition: 0.7s background-color;
  z-index: 1;
}
.img-viewer-actions:hover {
  cursor: pointer;
  background-color: rgba(0,0,0,0.1);
}
.img-viewer-actions > i {
  position: absolute;
  display: block;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 15px;
  margin-top: -25px;
  font-size: 50px;
  color: #ddd;
  text-align: center;
}
.img-viewer-right {
  right: 0;
}
.img-viewer-left {
  left: 0;
}
.event-type {
  padding-left: 15px;
}
.timelink-row {
  border-top: 1px solid #eee;
}
.home-welcome {
  margin-top: -36px;
  padding-top: 36px;
  position: relative;
  display: block;
  overflow: hidden;
}
.home-welcome-block {
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  width: 0;
  height: 0;
  display: block;
  overflow: hidden;
}
.home-welcome-block.visible {
  width: 100%;
  height: auto;
  -webkit-animation: slide-in 0.3s forwards;
  animation: slide-in 0.3s forwards;
}
@keyframes slide-in {
  100% { transform: translateX(0%); }
}
@-webkit-keyframes slide-in {
  100% { -webkit-transform: translateX(0%); }
}

.home-welcome-pagination {
  position: absolute;
  top: 0;
  right: 15px;
}
.home-welcome-pagination .pagination-action {
  background-color: rgba(0,0,0,0.1);
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  line-height: 28px;
  text-align: center;
  color: #fff;
  border: 1px solid #d9d9d9;
  margin: 2px;
  cursor: pointer;
  transition: background-color 0.4s;
}
.home-welcome-pagination .pagination-action:hover {
  background-color: rgba(0,0,0,0.2);
}
.home-welcome-pagination .pagination-action:active {
  background-color: rgba(0,0,0,0.3);
}
.home-welcome-pagination .pagination-action .next {
  right: 0;
}
.home-welcome-img {
  max-height: 80px;
  margin: 0 10px 5px 0;
  display: block;
  float: left;
}
.home-welcome-img > img {
  max-height: 80px;
  border: 0 none;
}
.home-welcome-body {
  display: block;
}
.cc-footer-img {
  max-height: 36px;
}
.popup-people {
  max-height: 240px;
  width: 400px;
}
.popup-people .tag-list-people {
  max-height: 140px;
}
.node-details-loader {
  margin-top: -15px;
  position: relative;
  margin-left: 5px;
  display: inline-block;
}
.lazylist > ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.affiliation-block {
  display: inline-block;
  font-size: 13px;
  padding-right: 4px;
}
.person-list-details {
  display: inline-block;
  vertical-align: middle;
}
.graph-context-menu {
  position: fixed;
  z-index:99;
  border: 1px solid #386a8c;
  background-color: #FFF;
  font-size: 13px;
  min-width: 120px;
}
.graph-context-menu > div {
  padding: 3px 10px;
  border-bottom: 1px solid #5293bf;
  cursor: default;
  transition: background-color 0.4s;
}
.graph-context-menu > div:hover {
  background-color: #5293bf;
  color: #fff;
}
.graph-context-menu > div:last-child {
  border-bottom: 0 none;
}
.extra-side-padding {
  padding: 0 15px;
}
.event-related-people {
  max-height: 150px;
  overflow-y: auto;
}
@media (min-width: 576px) and (max-width: 767px) {
  .container, .container-sm {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.people-tags-pagination {
  padding: 10px 0;
  display: inline-block;
}
.item-block {
  max-height: 600px;
  overflow-y: auto;
  margin-bottom: 10px;
  padding: 0;
  border-bottom: 1px solid #eee;
  transition: max-height 0.4s;
}
.item-block.item-hidden {
  max-height: 0;
}
.technical-metadata {
  padding: 15px 0 0 15px;
}
.technical-metadata div {
  padding-left: 15px;
}
.original-location {
  padding-bottom: 10px;
}
.tabs-container {
  display: block;
  position: relative;
}
.tabs-header {
  display: flex;
  align-items: flex-start;
  overflow-x: auto;
  transform: rotateX(180deg);
  padding-bottom: 10px;
}
.tab-header {
  border: 1px solid $blue;
  padding: 4px 8px;
  transition: all 0.1s;
  cursor: pointer;
  white-space: nowrap;
  transform: rotateX(180deg);
}
.tab-header.active {
  padding: 6px 8px;
  color: #fff;
  background-color: $light-blue;
}
.tab-header:hover {
  color: #fff;
  background-color: $blue;
}
.tabs-header.small > .tab-header {
  font-size: 12px;
  padding: 2px;
}
.tabs-header.small > .tab-header.active {
  font-size: 12px;
  padding: 4px 2px;
}
.tab-content {
  margin-top: -1px;
  padding: 4px;
  border: 1px solid $blue;
  display: none;
}
.tab-content.active {
  display: block;
}
.graph-search-checkboxes > label {
  padding-right: 12px;
  font-size: 13px;
  position: relative;
}
.graph-search-checkboxes > label:last-of-type {
  padding-right: 0;
}
.graph-search-checkboxes > label > input {
  z-index: 1;
  position: relative;
}
.flex {
  display: flex;
}
.radio-color-code {
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  top: 0;
  left: -4px;
  z-index: 0;
}
.graph-search-results-container {
  display: flex;
  margin: 4px 0;
  align-items: flex-start;
}
.graph-search-results-container .toggle-info-btn {
  margin-left: 8px;
}
.graph-search-results {
  padding: 0 10px;
  margin: 5px 0 0 -10px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  list-style-type: none;
  max-height: 300px;
  overflow-y: auto;
  transition: max-height 0.4s;
}
.graph-search-results.item-hidden {
  max-height: 0;
}
.graph-search-results > li {
  padding: 4px 4px 4px 0;
  border-bottom: 1px solid #ddd;
}
.graph-search-results > li:last-child {
  border-bottom: 0 none;
}
.graph-search-results > li:hover {
  background-color: #eee;
}
.graph-search-results > li.selected {
  background-color: $light-green;
}
.graph-search-results .details {
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
}
.graph-search-results .img-fluid, .graph-search-results .img-thumbnail {
  max-width: 40px;
  margin-right: 6px;
}
.graph-search-results .details > .color-code {
  width: 8px;
  align-self: stretch;
}
.graph-search-results .details > .label {
  padding-left: 4px;
  flex-grow: 1;
}
.graph-search-results .details > .actions {
  min-width: 110px;
}
.graph-search-results .details > .actions .btn {
  background-color: #fff;
}
.graph-search-results .details > .actions .btn:hover, 
.graph-search-results .details > .actions .btn:active, 
.graph-search-results .details > .actions .btn:focus {
  background-color: #858482;
}
.graph-search-results .details > .actions > button {
  display: block;
  margin-bottom: 4px;
}
.graph-search-results-node-details {
  position: absolute;
  right: 10px;
  bottom: 0;
  left: 10px;
  max-height: 0%;
  background-color: #fff;
  box-shadow: 0 -4px 8px rgba(0,0,0,0.2);
  overflow: hidden;
  transition: max-height 0.4s;
}
.graph-search-results-node-details.visible {
  max-height: 75%;
  border-top: 1px solid #ddd;
}
.graph-search-results-node-details-inner {
  padding: 15px;
  max-height: calc(100% - 62px);
  overflow-y: auto;
}
.graph-search-results-node-details-inner .item-details {
  max-width: 150px;
  float: right;
}
.graph-search-spinner {
  position: absolute;
  right: 70px;
  top: 10px;
  border-radius: 50% !important;
  z-index: 99;
}
.graph-search-paths-selected {
  padding: 5px;
}
.graph-search-paths-footer {
  display: flex;
  margin: 5px;
  justify-content: space-between;
}
.graph-progress {
  width: 230px;
  position: absolute;
  top: 20px;
  left: 50%;
  margin-left: -115px;
  padding: 10px 15px;
  box-shadow: 0 0 4px rgba(0,0,0,0.3);
  border-radius: 4px;
  background-color: #fff;
}
.graph-search-block {
  max-height: 60px;
  overflow-y: auto;
  display: block;
  font-size: small;
}
.graph-paths {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
}
.graph-path {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.graph-path-rel {
  font-style: italic;
  font-size: small;
  white-space: nowrap;
}
.graph-path .badge {
  cursor: pointer;
  white-space: normal !important;
}
.graph-classpiece {
  background-color: #1e9dd8 !important;
}
.graph-event {
  background-color: #c9730a !important;
}
.graph-organisation {
  background-color: #5343b7 !important;
}
.graph-person {
  background-color: #519b1b !important;
}
.graph-resource {
  background-color: #0982a0 !important;
}
.item-block-heading {
  display: flex;
  gap: 4px;
}
.item-block-heading > span {
  flex: 1 1;
}
.events-divider {
  border-bottom: 1px solid #eee;
  width: 100%;
  padding: 14px 0;
  position: relative;
  font-size: 1.1rem;
}
.events-divider > span {
  bottom: -2px;
  left: 8px;
  position: absolute;
  display: block;
}
.swiper-button-prev, .swiper-button-next {
  color: #CCC !important;
}
.swiper-button-prev:hover, .swiper-button-next:hover {
  color: #999 !important;
}
.swiper-button-prev:active, .swiper-button-next:active {
  color: #666 !important;
}
.swiper-pagination-bullet-active {
  background: $green !important;
}
.swiper-slide img {
  border: 1px solid #ccc;
  border-radius: 4px;
}