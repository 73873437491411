.heatmap-container .leaflet-popup-content {
  min-width: 400px;
}
.heatmap-container .map-search-container-nodes {
  max-height: 420px;
}
.heatmap-legend {
  position: absolute;
  bottom: 16px;
  right: 0;
  z-index: 999999;
  background-color: rgba(255,255,255,0.7);
  padding: 5px 15px 5px 5px;
}
.heatmap-legend > ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.heatmap-legend > ul > li {
  font-size: 12px;
  line-height: 12px;
  color: #555;
}
.heatmap-legend-color {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right:4px;
}
.map-container {
  width: 100%;
  height: 500px;
  display: block;
  overflow: hidden;
  position: relative;
}
.leaflet-container {
  display: block;
  width: 100%;
  height: 500px;
  position: relative;
}
.leaflet-default-icon-path {
  width: 30px !important;
  height: 30px !important;
  padding: 4px 8px;
  background-image: none!important;
}
.marker-cluster div span{
  opacity: 0.6;
  text-shadow: -1px -1px 5px #fff;
}
.marker-cluster-medium, .marker-cluster-small {
  background-color: transparent !important;
}
.marker-cluster-medium div, .marker-cluster-small div {
  background-color: transparent !important;
  font-weight: bold !important;
}
.map-search-toggle {
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 0;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  right: 12px;
  top: 80px;
  z-index: 999;
  box-shadow: 0 1px 3px rgba(0,0,0,0.7);
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;
}
.map-search-toggle:hover {
  background-color: #eee;
}
.map-search-toggle:active {
  background-color: #d0d0d0;
}
.map-help-toggle {
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 0;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  right: 12px;
  top: 125px;
  z-index: 999;
  box-shadow: 0 1px 3px rgba(0,0,0,0.7);
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;
}
.map-help-toggle:hover {
  background-color: #eee;
}
.map-help-toggle:active {
  background-color: #d0d0d0;
}
.map-search-container {
  width: 250px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.7);
  position: absolute;
  top: 0;
  right: -255px;
  transition: all 0.5s;
  z-index: 9999999;
}
.map-search-container.visible {
  right: 0;
}
.close-map-search-container {
  width: 20px;
  height: 20px;
  cursor: pointer;
  line-height: 20px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
}
.close-map-search-container:hover {
  color: #666;
}
.close-map-search-container:active {
  color: #000;
}
.map-search-input {
  padding: 0 15px;
  margin: 20px 0 10px 0;
  position: relative;
}
.map-search-input input {
  padding-right: 20px;
}
.map-search-input > i {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}
.map-search-input > i:hover {
  color: #666;
}
.map-search-input > i:active {
  color: #000;
}
.map-search-container-nodes {
  height: 530px;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.map-search-container-nodes::-webkit-scrollbar {
  display: none;
}
.map-search-container-nodes ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.map-search-container-nodes > ul > li {
  cursor: pointer;
  padding: 5px 15px;
  border-bottom: 1px solid #ddd;
}
.map-search-container-nodes > ul > li:first-child {
  border-top: 1px solid #ddd;
}
.map-search-container-nodes > ul > li:hover {
  background-color: #f9f9f9;
}
.map-search-container-nodes > ul > li:active {
  background-color: #f0f0f0;
}
